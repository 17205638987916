import { inject } from '@angular/core';
import { AuthStateService } from './auth-state.service';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

export const LoggedInAuthGuard: CanActivateFn = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  route: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  state: RouterStateSnapshot
): Observable<boolean> => {
  const router: Router = inject(Router);
  const auth: AuthStateService = inject(AuthStateService);
  if (!auth.isLogged()) {
    router.navigate(['/login']);
  }
  return toObservable(auth.isLogged);
};

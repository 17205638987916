import { Routes } from '@angular/router';
import { LoginComponent } from './authorization/login/login.component';
import { LoggedInAuthGuard } from './services/router-guards';

export const routes: Routes = [
  {
    path: 'login',
    title: 'Smart music',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    title: 'Smart music',
    loadComponent: () =>
      import('./authorization/forgot-password/forgot-password.component').then(
        c => c.ForgotPasswordComponent
      ),
  },
  {
    path: '',
    canActivate: [LoggedInAuthGuard],
    loadComponent: () =>
      import('./main/main-layout/main-layout.component').then(c => c.MainLayoutComponent),
    title: 'Smart music',
    children: [
      {
        path: 'main',
        canActivateChild: [LoggedInAuthGuard],
        loadChildren: () => import('./main/main-routs').then(r => r.NESTED_ROUTS),
      },

      {
        path: 'system',
        canActivateChild: [LoggedInAuthGuard],
        loadChildren: () => import('./system/system-routes').then(r => r.NESTED_ROUTS),
      },

      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'main',
      },
    ],
  },
];

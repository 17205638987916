<div class="container">
  <div class="centered-container">
    <div class="form-container">
      <div class="header-container">
        <app-toolbar [showLogo]="true">
          <span class="mr-4 ml-4">Smart music</span>
        </app-toolbar>
      </div>

      <div class="content-container">
        <mat-card class="no-out-footprint w-100">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-title class="helper-color-accent-400"
                >Welcome to Smart music!
              </mat-card-title>
              <mat-card-subtitle class="helper-color-accent-400"
                >Login to Your Account.</mat-card-subtitle
              >
            </mat-card-title-group>
          </mat-card-header>

          <!--BUG: ngSubmin fires on input type canges -->
          <form [formGroup]="formGroupAuth">
            <mat-card-content>
              <mat-form-field color="accent" class="w-100">
                <mat-label>Enter your login</mat-label>
                <input formControlName="username" matInput type="text" />
                <mat-hint align="end" class="helper-color-accent-400">required</mat-hint>
              </mat-form-field>
              <mat-form-field
                (keyup.enter)="OnSubmit()"
                (keydown.enter)="CancelEvent($event)"
                color="accent"
                class="w-100">
                <mat-label>Enter your password</mat-label>
                <input
                  formControlName="password"
                  matInput
                  [type]="passwordHide() ? 'password' : 'text'" />
                <button
                  tabindex="-1"
                  mat-icon-button
                  matSuffix
                  (click)="passwordHide.set(!passwordHide())"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="passwordHide()">
                  <mat-icon>{{ passwordHide() ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-hint class="helper-color-accent-400" align="end">
                  min 4 char typed: {{ formGroupAuth.controls['password'].value.length }}
                </mat-hint>
              </mat-form-field>
            </mat-card-content>
          </form>
        </mat-card>
      </div>

      <div class="footer-container">
        <mat-toolbar class="helper-bg-accent-50">
          <div class="spacer"></div>
          @if (dataIsLoading()) {
            <app-loader titlle="Credentional is checking" class="mr-2"></app-loader>
          }
          <button [disabled]="!submitEnabled()" mat-button color="accent" (click)="OnSubmit()">
            LOGIN
          </button>
        </mat-toolbar>
      </div>
    </div>
  </div>
</div>

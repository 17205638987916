import { ToolbarComponent } from './../../ui-components/toolbar/toolbar.component';
import { Component, inject, signal } from '@angular/core';
import { AuthStateService } from '../../services/auth-state.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FetchService } from '../../services/fetch.service';
import { finalize, take } from 'rxjs';
import { LoaderComponent } from '../../ui-components/loader/loader.component';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    LoaderComponent,
    ReactiveFormsModule,
    ToolbarComponent,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  auth: AuthStateService = inject(AuthStateService);
  fetch: FetchService = inject(FetchService);
  fb: FormBuilder = inject(FormBuilder);
  messenger: NotificationService = inject(NotificationService);

  passwordHide = signal(true);
  submitEnabled = signal(false);
  dataIsLoading = signal(false);

  formGroupAuth: FormGroup = this.fb.nonNullable.group({
    username: this.fb.control('', Validators.required),
    password: this.fb.control('', [Validators.required, Validators.minLength(4)]),
  });

  constructor() {
    this.formGroupAuth.statusChanges.pipe(takeUntilDestroyed()).subscribe(value => {
      this.submitEnabled.set(value === 'VALID' && this.formGroupAuth.dirty);
    });
  }

  OnSubmit() {
    if (!this.submitEnabled()) {
      return;
    }

    this.dataIsLoading.set(true);
    this.fetch
      .getUserData(this.formGroupAuth.value)
      .pipe(
        take(1),
        finalize(() => {
          this.dataIsLoading.set(false);
        })
      )
      .subscribe({
        next: data => {
          if (!data?.is_admin) {
            this.messenger.Error('login process', 'Access is denied');
          } else {
            this.messenger.Succes('login process', 'logged in OK');
            this.auth.Login(data);
          }
        },
        error: error => {
          const currentValue = this.formGroupAuth.value;
          this.formGroupAuth.reset(currentValue);
          this.messenger.Error('login process', this.messenger.GetErrorText(error));
        },
        complete: () => {
          this.dataIsLoading.set(false);
        },
      });
  }

  CancelEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}

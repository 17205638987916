import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DropZoneDirective } from './directives/drop-zone.directive';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  hostDirectives: [{ directive: DropZoneDirective, inputs: ['appDropZone'] }],
})
export class AppComponent {}
